<template>
  <div>
    <b-row v-show='false'>
      <b-col cols="6">
        <b-card title="Create Awesome 🙌">
          <b-card-text>This is your second page.</b-card-text>
          <b-card-text>Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin. Carrot cake dragée chupa chups jujubes. Macaroon liquorice cookie wafer tart marzipan bonbon. Gingerbread jelly-o dragée chocolate.</b-card-text>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card title="Create Awesome 🙌">
          <b-card-text>This is your second page.</b-card-text>
          <b-card-text>Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin. Carrot cake dragée chupa chups jujubes. Macaroon liquorice cookie wafer tart marzipan bonbon. Gingerbread jelly-o dragée chocolate.</b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="應收帳款">
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col cols="4" align-self="center">
                  <b-form-group label-cols="2" content-cols="9" label="稅別" label-for="qTax">
                    <b-form-input id="qTax" v-model="q.qTax"/>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label-cols="3" content-cols="9" label="交易日期" label-for="qGetDate">
                    <b-form-input id="qGetDate" v-model="q.qGetDate"/>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-button type="submit" class="mr-1" variant="primary">查詢</b-button>
                  <b-button type="submit" class="mr-1" variant="info" @click="showCreateDialog()">新增</b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <good-table-basic :parent-rows="tableBasic" title="test" @delete:row="showDeleteDialog()" @update:row="showUpdateDialog()"/>
          </b-col><!-- 發票明細 -->
        </b-row>
      </b-tab>
      <b-tab title="逾期應收">
      </b-tab>
      <b-tab title="應付帳款">
      </b-tab>
      <b-tab title="逾期應付">
      </b-tab>
    </b-tabs>
    <b-modal id="modal-update" ok-title="確定" cancel-title="取消" title="編輯視窗" :no-close-on-backdrop="true" size="lg" @ok="confirmUpdate()">
      <b-row>
        <b-col cols="3">
          <b-form-group label="憑證日期" label-for="docDate">
            <b-form-input id="docDate" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="交易日期" label-for="getDate">
            <b-form-input id="getDate" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="資金帳戶" label-for="accountingId">
            <b-form-input id="accountingId" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="交易對象" label-for="targetDisName">
            <b-form-input id="targetDisName" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="會計科目" label-for="accCode">
            <b-form-input id="accCode" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="稅別" label-for="taxType">
            <v-select
              id="taxType"
              v-model="taxType"
              :options="taxTypeOptions"
              :selectable="option => ! option.value.includes('select_value')"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="交易金額" label-for="amt">
            <b-form-input id="amt" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-row class="clearfix"></b-row>
        <b-col cols="12">
          <b-form-group label="備註" label-for="note">
            <b-form-input id="note" v-model="name"/>
          </b-form-group>
        </b-col>

      </b-row>
    </b-modal>
    <b-modal
      id="modal-delete"
      ok-title="確定"
      cancel-title="取消"
      centered
      title="刪除視窗"
      :no-close-on-backdrop="true"
      @ok="confirmDelete()"
    >
      <label for="" class="label-out">確定要刪除嗎嗎?</label> 
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCol, BRow, BFormGroup, BFormInput, BButton,BTab,BTabs
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import GoodTableBasic from './trans/GoodTableBasic.vue'

export default {
  components: {
    BCol,
    BTab,
    BTabs,
    BRow,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    GoodTableBasic,
  },
  data() {
    return {
      tableBasic: [],
      name: '',
      taxType: null,
      taxTypeOptions: [{value: '1', text: '應稅'}],
      q: {
        qTax: '',
        qGetDate: '',
      }
    }
  },
  created() {
    const Authorization = sessionStorage.getItem('token')
    const headers = {Authorization}
    const params = {
      cusNo: '83267911',
      orgId: '1',
    }
    this.$http.get('../api/bill/getBills', { params, headers }).then(res => {
      res.data.data.forEach(e => {
        console.log(e)
        this.tableBasic.push(e)
      })
    })
  },
  methods: {
    showCreateDialog(model) {
      this.$bvModal.show('modal-update')
    },
    showDeleteDialog(model) {
      this.$bvModal.show('modal-delete')
    },
    showUpdateDialog() {

      this.$bvModal.show('modal-update')
    },
    confirmUpdate(p) {
      p.preventDefault()
      this.toast('b-toaster-top-center', false)
      const rs = true
      if (rs) {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-update')
        })
      }
    },
    confirmDelete () {
      // p.preventDefault()
      this.toast('b-toaster-top-center', false)
      const rs = true
      if (rs) {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-delete')
        })
      }
    },
    toast(toaster, append = false) {
      this.counter += 1
      this.$bvToast.toast('更新成功', {
        title: `Toaster ${toaster}`,
        toaster,
        variant: 'success',
        solid: false,
        appendToast: append,
      })
    },
  },
}
</script>

<style lang="scss" >
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@/assets/scss/style.scss';


// .dark-layout label {
//     font-size: 16px;
// }
</style>
